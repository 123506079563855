<template>
  <div id="faq" class="container tw-pb-14 tw-mt-10 lg:tw-mt-10 pt-1 tw-scroll-mt-6">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-1">Have a Question?</h4>
        </div>
      </div>
    </div>

    <div class="row tw-m-0">
      <div class="row">
        <div class="col-md-6 col-12 pt-2">
          <div class="media">
            <help-circle-icon
                class="fea icon-ex-md text-primary mr-2 mt-1">
            </help-circle-icon>

            <div class="media-body">
              <h5 class="mt-0">
                Do I need docwriter.io?
              </h5>

              <p class="answer text-muted mb-0">
                If you have a Product, Software, a Theory, a Technology, or anything else description of which you want to share with other people or just have it for your own
                use, then with the help of <span class="tw-font-bold">docwriter.io</span> you can rapidly create (or replace your existing offline documentation)
                an easy readable online documentation, accessible 24/7.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 pt-2">
          <div class="media">
            <help-circle-icon
                class="fea icon-ex-md text-primary mr-2 mt-1">
            </help-circle-icon>

            <div class="media-body">
              <h5 class="mt-0">What content elements shall I be able to use?</h5>

              <p class="answer text-muted mb-0">
                Plenty! Check our <a class="text-primary" href="https://docs.docwriter.io/kb/en/content-editing/markdown-syntax" target="_blank">demo</a>
                with all the elements docwriter.io supports at the moment.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="media">
            <help-circle-icon
                class="fea icon-ex-md text-primary mr-2 mt-1">
            </help-circle-icon>

            <div class="media-body">
              <h5 class="mt-0">
                Can I use docwriter.io as a user-manual / guide for my software users?
              </h5>

              <p class="answer text-muted mb-0">
                Definitely. In <span class="tw-font-bold">docwriter.io</span> every header
                in every content page has a deep-link. You can “mirror” the structure of
                your software in <span class="tw-font-bold">docwriter.io</span> and put
                help icons in the software with deep-links to exact corresponding section
                in the contents. Check how the little help icons on our <a class="text-primary" :href="signUpLink.url" target="_blank">Sign-up page</a> work!
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="media">
            <help-circle-icon
                class="fea icon-ex-md text-primary mr-2 mt-1">
            </help-circle-icon>

            <div class="media-body">
              <h5 class="mt-0">
                What is a Project?
              </h5>

              <p class="answer text-muted mb-0">
                A Project is a set of Documentations (or books, if you will) having its own Domain and Management Team.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="media">
            <help-circle-icon
                class="fea icon-ex-md text-primary mr-2 mt-1">
            </help-circle-icon>

            <div class="media-body">
              <h5 class="mt-0">
                What is a Documentation?
              </h5>

              <p class="answer text-muted mb-0">
                A Documentation is a set of structured Content Pages. A Documentation can have its own name, design, localization, and security settings.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="media">
            <help-circle-icon
                class="fea icon-ex-md text-primary mr-2 mt-1">
            </help-circle-icon>

            <div class="media-body">
              <h5 class="mt-0">
                What is a Media Storage?
              </h5>

              <p class="answer text-muted mb-0">
                A Media Storage is a place (in the scope of a Project) where you can upload and store your images, excel sheets, and other types of files.
                You can easily reference the files in the contents of your Documentations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex-center tw-mt-14">
      <a
          v-b-modal.ContactModal
          data-toggle="modal"
          data-target="#ContactModal"
          class="btn btn-primary">

        <i class="mdi mdi-phone"></i> Contact us
      </a>
    </div>

    <b-modal
        size="xl"
        id="ContactModal"
        centered
        hide-header
        hide-footer>

      <div class="bg-white p-lg-3 rounded box-shadow tw-relative">
        <div class="tw-absolute tw-right-2 tw-top-2">
          <x-icon
              @click="$bvModal.hide('ContactModal')"
              size="1.5x"
              class="fea tw-text-gray-500 tw-cursor-pointer">
          </x-icon>
        </div>

        <div class="container tw-px-1 py-3">
          <div class="row align-items-center mt-sm-5 mt-lg-0">
            <div class="col-12 col-lg-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="card custom-form rounded shadow border-0">
                <div class="card-body">
                  <div id="message"></div>

                  <form @submit="sendMessage">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>
                            Your Name <span class="text-danger">*</span>
                          </label>

                          <div class="position-relative">
                            <user-icon
                                class="fea icon-sm icons">
                            </user-icon>

                            <input
                                type="text"
                                required
                                class="form-control pl-5"
                                v-model="contactForm.name"
                                placeholder="Your Name:"
                                maxlength="255"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>
                            Your Email <span class="text-danger">*</span>
                          </label>

                          <div class="position-relative">
                            <mail-icon
                                class="fea icon-sm icons">
                            </mail-icon>

                            <input
                                type="email"
                                required
                                class="form-control pl-5"
                                v-model="contactForm.email"
                                placeholder="Your email:"
                                maxlength="255"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>
                            Message <span class="text-danger">*</span>
                          </label>

                          <div class="position-relative">
                            <message-circle-icon
                                class="fea icon-sm icons">
                            </message-circle-icon>

                            <textarea
                                rows="4"
                                required
                                class="form-control pl-5"
                                v-model="contactForm.message"
                                placeholder="Your Message:"
                                maxlength="5000"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                        v-if="contactForm.errors.length > 0"
                        class="tw-rounded-md tw-bg-red-100 tw-flex-center tw-flex-col tw-items-stretch tw-p-2 tw-mb-4 tw-space-y-1">

                      <div
                          v-for="error in contactForm.errors"
                          class="tw-text-red-700 tw-text-center tw-text-sm">

                        {{ error }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 text-center">
                        <button
                            type="submit"
                            class="submitBnt btn btn-primary btn-block tw-text-white tw-flex-center"
                            value="Send Message">

                          <span v-if="contactForm.isLoading">
                              <svg-spinner-circle/>
                          </span>

                          <span>Send Message</span>
                        </button>

                        <div id="simple-msg"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-5 mt-4 mt-lg-0">
              <div class="title-heading ml-lg-4">
                <h4 class="mb-4">Contact Details</h4>

                <p class="text-muted">
                  Have another question?
                  Feel free to contact us and we'll try to help!
                </p>

                <div class="media contact-detail align-items-center mt-3">
                  <div class="icon">
                    <mail-icon
                        class="fea icon-m-md text-dark mr-3">
                    </mail-icon>
                  </div>

                  <div class="media-body content">
                    <h5 class="title font-weight-bold mb-0">
                      Email
                    </h5>

                    <a :href="`mailto:${contactEmail}`" target="_blank" class="text-primary">
                      {{ contactEmail }}
                    </a>
                  </div>
                </div>

                <div class="media contact-detail align-items-center mt-3">
                  <div class="icon">
                    <phone-icon
                        class="fea icon-m-md text-dark mr-3">
                    </phone-icon>
                  </div>

                  <div class="media-body content">
                    <h5 class="title font-weight-bold mb-0">
                      Phone
                    </h5>

                    <a href="tel:+152534-468-854" target="_blank" class="text-primary">
                      +371 673 997 73
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import contactApi from '../../api/contactApi'
import SvgSpinnerCircle from '../Svg/SpinnerCircle'
import {navigationLinks} from '@/store';
import {
  UserIcon,
  MailIcon,
  MessageCircleIcon,
  PhoneIcon,
  HelpCircleIcon,
  XIcon,
} from 'vue-feather-icons';

export default {
  components: {
    HelpCircleIcon,
    UserIcon,
    MailIcon,
    MessageCircleIcon,
    PhoneIcon,
    XIcon,
    SvgSpinnerCircle,
  },
  data() {
    return {
      contactForm: {
        name: '',
        email: '',
        message: '',
        errors: [],
        isLoading: false,
      },
      navigationLinks: navigationLinks,
    }
  },
  methods: {
    async sendMessage(event) {
      event.preventDefault()
      this.contactForm.isLoading = true
      let recaptchaToken = ''
      try {
        recaptchaToken = await this.$recaptcha('contact_form')
      } catch (error) {
        this.contactForm.isLoading = false
        return this.showRecaptchaError()
      }
      try {
        await contactApi.sendMessage({
          ...this.contactForm,
          recaptchaToken,
        })
        this.showSuccessNotification()
        this.closeContactModal()
        this.resetContactForm()
      } catch (error) {
        if (error.response?.data?.violations) {
          this.contactForm.errors = error.response.data.violations
        } else {
          this.showUnknownError()
        }
      }
      this.contactForm.isLoading = false
    },
    resetContactForm() {
      this.contactForm.name = ''
      this.contactForm.email = ''
      this.contactForm.message = ''
      this.contactForm.errors = []
    },
    async showRecaptchaBadge() {
      await this.$recaptchaLoaded()
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
    },
    async hideRecaptchaBadge() {
      await this.$recaptchaLoaded()
      const recaptcha = this.$recaptchaInstance
      recaptcha.hideBadge()
    },
    onContactModalShow() {
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        if (modalId === 'ContactModal') {
          this.showRecaptchaBadge()
        }
      })
    },
    onContactModalHide() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'ContactModal') {
          this.hideRecaptchaBadge()
        }
      })
    },
    showSuccessNotification() {
      this.$bvToast.toast(`Your message has been sent!`, {
        toaster: 'b-toaster-top-center',
        noCloseButton: true,
        bodyClass: 'bg-primary tw-text-white tw-rounded-inherit',
        solid: true,
      })
    },
    closeContactModal() {
      this.$bvModal.hide('ContactModal')
    },
    showUnknownError() {
      this.$bvToast.toast(`An unknown error has occurred. Please try again!`, {
        toaster: 'b-toaster-top-center',
        noCloseButton: true,
        bodyClass: 'bg-danger tw-text-white tw-rounded-inherit',
        solid: true,
      })
    },
    showRecaptchaError() {
      this.$bvToast.toast(`Recaptcha verification failed.`, {
        toaster: 'b-toaster-top-center',
        noCloseButton: true,
        bodyClass: 'bg-danger tw-text-white tw-rounded-inherit',
        solid: true,
      })
    },
  },
  computed: {
    contactEmail() {
      return process.env.VUE_APP_EMAIL_RECIPIENT_ADDRESS
    },
    signUpLink() {
      return navigationLinks.find(link => {
        return link.name == 'Sign up'
      })
    },
  },
  mounted() {
    this.onContactModalShow()
    this.onContactModalHide()
  }
};
</script>
