<template>
  <div id="pricing" class="container tw-pb-20 tw-pt-14 md:tw-pt-24">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center">
          <h4 class="title">
            Check our <span class="text-primary">simple pricing</span>
          </h4>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card pricing-rates business-rate border-0 p-4 rounded-md shadow">
          <div class="card-body p-0">
            <span class="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">
                Basic
            </span>

            <h2 class="font-weight-bold mb-0 mt-3">Free</h2>

            <div class="mt-1">
              <a :href="signUpLink.url" target="_blank" class="btn btn-block btn-primary">
                Sign Up
              </a>
            </div>

            <ul class="list-unstyled pt-4">
              <li class="h6 text-muted mb-0 tw-flex">
                <span class="tw-mb-2 tw-text-primary tw-mt-[2px]">
                    Includes:
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    1 Project
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    1 Documentation
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Multilingual content
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Project Team feature
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Markdown and WYSIWYG editors
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    docwriter.io subdomain of your choice
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    100MB (Megabytes) of Media Storage space
                    <div><small><strong>€1.99 per every extra 20GB</strong></small></div>
                </span>
              </li>

              <li><hr /></li>

              <li class="h6 text-muted mb-0 tw-flex mt-4 tw-italic">
                * Free plan may include promotional materials of docwriter.io & Arkbauer
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card pricing-rates business-rate border-0 p-4 rounded-md shadow">
          <div class="ribbon ribbon-right ribbon-warning overflow-hidden">
            <span class="text-center d-block shadow small h6">
                Featured
            </span>
          </div>

          <div class="card-body p-0">
            <span class="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">
                Business
            </span>

            <h2 class="font-weight-bold mb-0 mt-3">
              <sup>€</sup>9.99 <span class="tw-text-[1.4rem]">/ month</span>
            </h2>

            <div class="mt-1">
              <a :href="`${signUpLink.url}?plan=plan-business`" target="_blank" class="btn btn-block btn-primary">
                14 day trial available
              </a>
            </div>

            <ul class="list-unstyled pt-4">
              <li class="h6 text-muted mb-0 tw-flex">
                <span class="tw-mb-2 tw-text-primary tw-mt-[2px]">
                    Everything from Basic plan plus:
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    3 Projects
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    3 Documentations per Project
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Custom domains
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    20GB (Gigabytes) of Media storage space
                    <div><small><strong>€1.99 per every extra 20GB</strong></small></div>
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Design customization
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Password protection
                </span>
              </li>

              <li class="h6 text-muted mb-0 tw-flex">
                <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                </span>

                <span class="tw-mb-2 tw-mt-[2px]">
                    Google Analytics tracker
                </span>
              </li>

              <li><hr /></li>

              <li class="h6 text-muted mb-0 tw-flex mt-4 tw-italic">
                * Does not include promotional materials
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card pricing-rates business-rate border-0 p-4 rounded-md shadow">
          <div class="card-body p-0">
            <span class="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">
                Custom
            </span>

            <h2 class="font-weight-bold mb-0 mt-3">
              <span class="tw-text-[1.4rem]">Custom pricing</span>
            </h2>

            <div class="mt-1">
              <a
                  v-b-modal.ContactModal
                  data-toggle="modal"
                  data-target="#ContactModal"
                  class="btn btn-block btn-primary">

                <i class="mdi mdi-phone"></i> Contact us
              </a>
            </div>

            <ul class="list-unstyled pt-4">
              <li class="h6 text-muted mb-0 tw-flex">
                <span class="tw-mb-2 tw-text-primary tw-mt-[2px]">
                    In case something specific is required
                </span>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {navigationLinks} from '@/store';

export default {
  computed: {
    signUpLink() {
      return navigationLinks.find(link => {
        return link.name == 'Sign up'
      })
    },
  }
};
</script>
