<template>
    <div class="tw-mt-14 lg:tw-mt-24">
        <div class="position-relative">
            <div class="shape overflow-hidden text-footer">
                <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">

                    <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor">
                    </path>
                </svg>
            </div>
        </div>

        <footer class="footer">
            <div class="container">
                <!-- Navigation links -->
                <div class="tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-5 tw-justify-center">
                    <router-link v-for="link in navigationLinks" :key="link.name" :to="link.url" :target="link.target" class="tw-text-white/70">
                        {{ link.name }}
                    </router-link>
                </div>

                <div class="tw-mt-8 tw-flex tw-flex-wrap tw-gap-10 tw-justify-center">
                    <a
                        v-b-modal.ContactModal
                        data-toggle="modal"
                        data-target="#ContactModal"
                        class="btn btn-primary">

                        <i class="mdi mdi-phone"></i> Contact us
                    </a>
                </div>

                <!-- Social icons -->
<!--                <div class="tw-flex tw-flex-wrap tw-gap-10 tw-justify-center mt-5">-->
<!--                    <instagram-icon></instagram-icon>-->
<!--                    <facebook-icon></facebook-icon>-->
<!--                    <twitter-icon></twitter-icon>-->
<!--                    <mail-icon></mail-icon>-->
<!--                </div>-->

                <div class="tw-mt-10 tw-flex tw-flex-wrap tw-gap-10 tw-justify-center mx-auto tw-items-center">
                    <div class="tw-text-white tw-text-3xl tw-mb-0.5 tw-font-ephesis">
                        Made by
                    </div>

                    <div class="tw-w-48">
                        <a href="https://www.arkbauer.com/" target="_blank">
                            <svg-arkbauer-logo />
                        </a>
                    </div>
                </div>

                <!-- Copyright notice -->
<!--                <p class="text-center mt-5">-->
<!--                    © {{ new Date().getFullYear() }} docwriter.io All rights reserved.-->
<!--                </p>-->
            </div>
        </footer>
    </div>
</template>

<script>
    import { navigationLinks } from '@/store';
    import SvgArkbauerLogo from '@/components/Svg/ArkbauerLogo.vue'

    import {
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        MailIcon,
    } from 'vue-feather-icons';

    export default {
        components: {
            FacebookIcon,
            InstagramIcon,
            TwitterIcon,
            MailIcon,
            SvgArkbauerLogo,
        },

        data() {
            return {
                navigationLinks: navigationLinks,
            }
        },

        computed: {
            signUpLink() {
                return navigationLinks.find(link => {
                    return link.name == 'Sign up'
                })
            },
        }
    };
</script>
