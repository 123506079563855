<template>
    <div class="container-fluid mt-100 mt-60">
        <div class="bg-primary tw-bg-cta tw-bg-right lg:tw-bg-center rounded shadow py-5">
            <div class="container my-md-5">
                <div class="text-center">
                    <div class="section-title">
                        <h4 class="title text-white title-dark mb-4">
                            Ready to get started?
                        </h4>
                    </div>

                    <a
                        :href="signUpLink.url"
                        target="_blank"
                        type="submit"
                        class="btn btn-pills btn-light">
                        Sign up now
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { navigationLinks } from '@/store';

    export default {
        computed: {
            signUpLink() {
                return navigationLinks.find(link => {
                    return link.name == 'Sign up'
                })
            },
        }
    };
</script>
