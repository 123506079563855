<template>
    <div id="fun-facts" class="container tw-mt-12 lg:tw-mt-24 pt-1 tw-scroll-mt-7">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">
                      Check out these <span class="text-primary">fun facts</span>
                    </h4>
                </div>
            </div>
        </div>

        <div class="row" id="counter">
            <div class="col-md-3 col-6">
                <div class="counter-box text-center">
                    <img
                        src="../../assets/img/Asset190.svg"
                        class="avatar avatar-small"
                        :alt="`Docwriter - Number of created projects (${appName})`"
                        title="Projects"
                    />

                    <h2 class="mb-0 mt-4">
                        <countTo
                            ref="projectsCount"
                            :startVal="0"
                            :endVal="projectsCount"
                            :autoplay="false"
                            :duration="4000">
                        </countTo>
                    </h2>

                    <h6 class="counter-head text-muted">
                        Projects
                    </h6>
                </div>
            </div>

            <div class="col-md-3 col-6">
                <div class="counter-box text-center">
                    <img
                        src="../../assets/img/Asset189.svg"
                        class="avatar avatar-small"
                        :alt="`Docwriter - Number of created documentations (${appName})`"
                        title="Documentations"
                    />

                    <h2 class="mb-0 mt-4">
                        <countTo
                            ref="documentationsCount"
                            :startVal="0"
                            :endVal="documentationsCount"
                            :autoplay="false"
                            :duration="4000">
                        </countTo>
                    </h2>

                    <h6 class="counter-head text-muted">
                        Documentations
                    </h6>
                </div>
            </div>

            <div class="col-md-3 col-6">
                <div class="counter-box text-center">
                    <img
                        src="../../assets/img/Asset186.svg"
                        class="avatar avatar-small"
                        :alt="`Docwriter - Number of created content pages (${appName})`"
                        title="Content pages"
                    />

                    <h2 class="mb-0 mt-4">
                        <countTo
                            ref="contentPagesCount"
                            :startVal="0"
                            :endVal="contentPagesCount"
                            :autoplay="false"
                            :duration="4000">
                        </countTo>
                    </h2>

                    <h6 class="counter-head text-muted">
                        Content pages
                    </h6>
                </div>
            </div>

            <div class="col-md-3 col-6">
                <div class="counter-box text-center">
                    <img
                        src="../../assets/img/Asset187.svg"
                        class="avatar avatar-small"
                        :alt="`Docwriter - Number users (docwriter.io - ${appName})`"
                        title="Users"
                    />

                    <h2 class="mb-0 mt-4">
                        <countTo
                            ref="usersCount"
                            :startVal="0"
                            :endVal="usersCount"
                            :autoplay="false"
                            :duration="4000">
                        </countTo>
                    </h2>

                    <h6 class="counter-head text-muted">
                        Users
                    </h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import countTo from 'vue-count-to';
    import { appName } from '@/store';
    import funFactsApi from '../../api/funFactsApi'

    export default {
        components: { countTo },

        data() {
            return {
                usersCount: 0,
                appName: appName,
                projectsCount : 0,
                contentPagesCount: 0,
                documentationsCount: 0,
            }
        },

        methods: {
            async fetchFunFacts() {
                const { data: funFacts } = await funFactsApi.get()

                this.usersCount          = funFacts.usersCount
                this.projectsCount       = funFacts.projectsCount
                this.contentPagesCount   = funFacts.contentPagesCount
                this.documentationsCount = funFacts.documentationsCount

                this.startCounters()
            },

            startCounters() {
                this.$refs.usersCount.start()
                this.$refs.projectsCount.start()
                this.$refs.contentPagesCount.start()
                this.$refs.documentationsCount.start()
            }
        },

        created() {
            this.fetchFunFacts()
        },
    };
</script>
