<template>
    <div>
        <section class="bg-half-260 bg-primary d-table w-100 tw-bg-hero tw-bg-center tw-pt-20 md:tw-pt-44">
            <div class="bg-overlay"></div>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title-heading text-center mt-4">
                            <h1 class="display-4 title-dark font-weight-bold text-white mb-3">
                                Knowledge Base & Documentation platform for People
                            </h1>

                            <p class="para-desc mx-auto text-white">
                                Markdown language-based tool for creating an easy to use online Documentation for your Project
                            </p>

                            <div class="mt-4 pt-2">
                                <a :href="signUpLink.url" target="_blank" class="btn btn-primary">
                                    Get Started
                                </a>
                            </div>

                            <div
                                class="tw-relative tw-top-24 tw-w-24 mx-auto tw-opacity-80 transition
                                       hover:tw-opacity-100 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2">

                                <div class="tw-text-white tw-text-3xl tw-mb-0.5 tw-font-ephesis">
                                    Made by
                                </div>

                                <div class="tw-w-48">
                                    <a href="https://www.arkbauer.com/" target="_blank">
                                        <svg-arkbauer-logo />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="position-relative">
            <div class="shape overflow-hidden text-white">
                <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">

                    <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor">
                    </path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import { navigationLinks } from '@/store';
    import SvgArkbauerLogo from '@/components/Svg/ArkbauerLogo.vue'

    export default {
        components: { SvgArkbauerLogo },

        computed: {
            signUpLink() {
                return navigationLinks.find(link => {
                    return link.name == 'Sign up'
                })
            },
        }
    };
</script>
