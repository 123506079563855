<template>
    <div class="bg-light tw-pt-10 tw-pb-10 md:tw-pb-20">
        <div class="container lg:tw-mt-5">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                        <h4 class="title mb-4">
                            See Docwriter
                            <span class="text-primary">in action</span>
                        </h4>

                        <p class="text-muted para-desc mb-0 mx-auto">
                            Check out these amazing documentations built with
                            <span class="tw-text-gray-700 font-weight-bold">docwriter.io</span>
                        </p>
                    </div>
                </div>
            </div>

            <agile @after-change="event => currentSlide = event.currentSlide">
                <img
                    v-for="customer in customerPreviews"
                    class="slide"
                    :alt="`Docwriter - Customer documentation preview (${appName})`"
                    title="Customer preview"
                    :src="customer.image"
                />

                <template slot="prevButton">
                    <div class="agile-nav-btn">
                        <i class="mdi mdi-chevron-left tw-relative tw-left-[-2px]"></i>
                    </div>
                </template>

                <template slot="nextButton">
                    <div class="agile-nav-btn">
                        <i class="mdi mdi-chevron-right"></i>
                    </div>
                </template>

                <template slot="caption">
                    <div class="caption__description">
                        {{ customerPreviews[currentSlide].description }}

                        <div class="mt-3">
                            <a
                                :href="customerPreviews[currentSlide].link.url"
                                target="_blank"
                                class="btn btn-primary mr-2">
                                Visit
                            </a>
                        </div>
                    </div>
                </template>
            </agile>

            <div class="agile__caption-mobile">
                {{ customerPreviews[currentSlide].description }}

                <div class="mt-3">
                    <a
                        :href="customerPreviews[currentSlide].link.url"
                        target="_blank"
                        class="btn btn-primary mr-2">
                        Visit
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { appName } from '@/store';

    export default {
        data() {
            return {
                appName: appName,
                currentSlide: 0,

                customerPreviews: [
                  {
                    title: 'WildMe',
                    description: 'Wild Me builds open software and artificial intelligence for the conservation research community.',
                    image: require('../../assets/img/WildMe-preview.jpg'),
                    link: {
                      name: 'https://docs.wildme.org',
                      url: 'https://docs.wildme.org'
                    }
                  },



                    {
                        title: '3RPMS.de',
                        description: 'A Knowledge Base for a popular Property Management System 3RPMS ® serving hundreds of hotels in Germany',
                        image: require('../../assets/img/3rpms-preview.jpg'),
                        link: {
                            name: 'https://3rpms.docwriter.io',
                            url: 'https://3rpms.docwriter.io/knowledgebase/de/'
                        }
                    },

                    {
                        title: 'Phoxio',
                        description: 'A real time notification integration system with ease of use and developer friendly API',
                        image: require('../../assets/img/phoxio-preview.jpg'),
                        link: {
                            name: 'https://phoxio.docwriter.io',
                            url: 'https://phoxio.docwriter.io/user-api-documentation/en/introduction/getting-started/'
                        }
                    },

                    {
                        title: 'Neuronce',
                        description: 'Resources for science teachers, created in a collaborative project between science teachers and educators all around the world.',
                        image: require('../../assets/img/neuronce-preview.jpg'),
                        link: {
                            name: 'https://neuronce.docwriter.io',
                            url: 'https://neuronce.docwriter.io/project-planning/en/'
                        },
                    },
                ],
            }
        },
    }
</script>

<style>
    .agile {
        margin: 0 auto;
        width: 100%;
        height: 288px;
        border-radius: 10px;
        overflow: hidden;
    }

    .agile__wrapper {
        margin: 0 auto;
        width: 336px;
        border-radius: 10px;
        text-align: center;
        background: #ebecf1;
        padding: 22px 10px 18px 10px;
    }

    .agile__nav-button {
        background: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 36px;
        position: absolute;
        transition-duration: 0.3s;
        width: 80px;
        top: 50%;
        transform: translateY(-50%);
    }

    .agile__nav-button--prev {
        left: 0;
    }

    .agile__nav-button--next {
        right: 0px;
    }

    .agile__dots {
        display: none;
        bottom: 10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    .agile__dot {
        margin: 0 10px;
    }

    .agile__dot button {
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: 0.3s;
        width: 10px;
    }

    .agile__dot--current button, .agile__dot:hover button {
        background-color: #fff;
    }

    .slide {
        display: block;
        height: 500px;
        object-fit: contain;
        object-position: top;
        width: 100%;
    }

    .agile__caption {
        position: absolute;
        font-size: 1.2rem;
        align-items: center;
        display: flex;
        bottom: -100%;
        left: 0;
        color: #fff;
        background: #0000008c;
        width: 100%;
        height: 100%;
        padding: 110px;
        backdrop-filter: blur(1px);
        transition: .3s;
    }

    .agile__caption-mobile {
        margin-top: 20px;
        text-align: center;
    }

    .agile-nav-btn {
        background: #00000091;
        border-radius: 70px;
        width: 40px;
        height: 40px;
        display: inline-block;
        padding-left: 3px;
        line-height: 41px;
    }

    .caption__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @media only screen and (min-width: 576px) {
        .agile:hover .agile__caption {
            bottom: 0;
        }

        .agile {
            width: 500px;
            height: 400px;
        }

        .agile__caption-mobile {
            display: none;
        }

        .agile__wrapper {
            margin: 0;
            background: transparent;
            width: auto;
        }

        .slide {
            object-fit: cover;
        }
    }

    @media only screen and (min-width: 768px) {
        .agile {
            width: 700px;
            height: 500px;
        }
    }

    @media only screen and (min-width: 1200px) {
        .agile {
            width: 800px;
            height: 500px;
        }
    }
</style>
