class contactApi
{
    static sendMessage(data) {
        return axios.post(
            `//${process.env.VUE_APP_ADMIN_APP_HOST}/contact/send-message`,
            data
        )
    }
}

export default contactApi
