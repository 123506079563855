<template>
    <div>
        <AppNavbar :nav-light="true" />

        <div class="overflow-hidden">
            <HeroSection />
            <PreviewSection />
            <PricingSection />
            <CustomerPreviewSection />
            <FaqSection />
            <DocumentationSection />
            <FunFactsSection />
            <SignUpSection />
        </div>

        <AppFooter />
    </div>
</template>

<script>
    import AppNavbar from '@/components/AppNavbar';
    import AppFooter from '@/components/AppFooter';
    import FaqSection from '@/components/Sections/FaqSection';
    import HeroSection from '@/components/Sections/HeroSection';
    import SignUpSection from '@/components/Sections/SignUpSection';
    import PreviewSection from '@/components/Sections/PreviewSection';
    import PricingSection from '@/components/Sections/PricingSection';
    import FunFactsSection from '@/components/Sections/FunFactsSection'
    import DocumentationSection from '@/components/Sections/DocumentationSection';
    import CustomerPreviewSection from '@/components/Sections/CustomerPreviewSection';

    export default {
        components: {
            AppNavbar,
            AppFooter,
            FaqSection,
            HeroSection,
            SignUpSection,
            PreviewSection,
            PricingSection,
            FunFactsSection,
            DocumentationSection,
            CustomerPreviewSection,
        },
    };
</script>
