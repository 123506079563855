class funFactsApi
{
    static get() {
        return axios.get(
            `//${process.env.VUE_APP_ADMIN_APP_HOST}/statistics/`
        )
    }
}

export default funFactsApi
