<template>
    <div>
        <!-- Navbar Start -->
        <header
            id="topnav"
            class="defaultscroll sticky"
            :class="{ 'bg-white': isWhiteNavbar === true }">

            <div class="container">
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div>
                        <router-link to="/"
                            v-if="navLight !== true"
                            class="logo tw-cursor-pointer">

                            <img
                                src="../assets/img/logo-text-default.svg"
                                width="140"
                                :alt="`Docwriter - Logo (${appName}})`"
                                title="Logo"
                            />
                        </router-link>

                        <router-link to="/"
                            v-else
                            class="logo tw-cursor-pointer">

                            <img
                                src="../assets/img/logo-text-default.svg"
                                class="l-dark"
                                width="150"
                                :alt="`Docwriter - Logo (${appName})`"
                                title="Logo"
                            />

                            <img
                                src="../assets/img/logo-text-white.svg"
                                class="l-light"
                                width="150"
                                :alt="`Docwriter - Logo (${appName})`"
                                title="Logo"
                            />
                        </router-link>
                    </div>

                    <div id="navigation">
                        <!-- Navigation Menu-->
                        <ul
                            class="navigation-menu"
                            :class="{ 'nav-light': navLight === true }">

                            <li
                                v-for="link in navigationLinks"
                                :key="link.name"
                                :class="{ 'd-block d-lg-none': link.name == 'Log in' || link.name == 'Sign up' }">

                                <router-link @click="toggleMenu" :to="link.url" :target="link.target" class="side-nav-link-ref">
                                    {{ link.name }}
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="d-none d-lg-block">
                        <a
                            :href="loginLink.url"
                            target="_blank"
                            class="btn btn-primary mr-2">
                            {{ loginLink.name }}
                        </a>

                        <a
                            :href="signUpLink.url"
                            target="_blank"
                            class="btn"
                            :class="{ 'btn-light': navLight === true,
                                      'btn-primary': navLight !== true }">
                            {{ signUpLink.name }}
                        </a>
                    </div>

                    <div class="tw-flex tw-items-center">
                        <a
                            :href="loginLink.url"
                            target="_blank"
                            class="btn btn-primary mr-2 d-block d-lg-none">
                            {{ loginLink.name }}
                        </a>

                        <div class="menu-item">
                            <!-- Mobile menu toggle-->
                            <a
                                class="navbar-toggle"
                                @click="toggleMenu"
                                :class="{ open: isCondensed === true }">

                                <div class="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    import { navigationLinks, appName } from '@/store';

    export default {
        props: {
            isWhiteNavbar: {
                type: Boolean,
            },

            navLight: {
                type: Boolean,
            },

            isIcons: {
                type: Boolean,
            },
        },

        data() {
            return {
                isCondensed: false,
                navigationLinks: navigationLinks,
                appName: appName,
            };
        },

        methods: {
            toggleMenu() {
                this.isCondensed = !this.isCondensed;

                if (this.isCondensed) {
                    document.getElementById("navigation").style.display = "block";
                } else {
                    document.getElementById("navigation").style.display = "none";
                }
            },

            scrollToTop() {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            },

            onMenuClick(event) {
                event.preventDefault();
                const nextEl = event.target.nextSibling.nextSibling;

                if (nextEl && !nextEl.classList.contains("open")) {
                    const parentEl = event.target.parentNode;

                    if (parentEl) {
                        parentEl.classList.remove("open");
                    }

                    nextEl.classList.add("open");
                } else if (nextEl) {
                    nextEl.classList.remove("open");
                }

                return false;
            },

            closeMobileMenu(event) {
                if (! event.target.closest('.navbar-toggle')) {
                    this.isCondensed = false;

                    document.getElementById("navigation").style.display = 'none'
                }
            }
        },

        mounted: () => {
            window.onscroll = function () {
                onwindowScroll();
            };

            function onwindowScroll() {
                if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                    if (document.getElementById("topnav")) {
                        document.getElementById("topnav").classList.add("nav-sticky");
                    }
                } else {
                    if (document.getElementById("topnav")) {
                        document.getElementById("topnav").classList.remove("nav-sticky");
                    }
                }

                if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                    if (document.getElementById("back-to-top")) {
                        document.getElementById("back-to-top").style.display = "inline";
                    }
                } else {
                    if (document.getElementById("back-to-top")) {
                        document.getElementById("back-to-top").style.display = "none";
                    }
                }
            }
        },

        computed: {
            loginLink() {
                return navigationLinks.find(link => {
                    return link.name == 'Log in'
                })
            },

            signUpLink() {
                return navigationLinks.find(link => {
                    return link.name == 'Sign up'
                })
            },
        },

        created() {
            document.addEventListener('click', this.closeMobileMenu)
        },
    };
</script>
